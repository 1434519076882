import React, { FC } from 'react';

import extractUrlFromMultiPicker from 'gatsby-theme-dettol/src/utils/urlMultiPicker';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import UmbracoImage from 'gatsby-theme-dettol/src/components/common/Image/UmbracoImage';
import HTag from 'gatsby-theme-dettol/src/components/common/HTag';
import IntroProducts from './IntroProducts';
import { TIntroComponentProps } from './models';

import './Intro.scss';

export const Intro: FC<TIntroComponentProps> = ({
  regularTitle,
  boldTitle,
  descriptionText,
  buttonText,
  image,
  imageAlt,
  buttonURL,
  introProducts,
  sectionLandmark,
}) => {

  return (
    <section
      className="dt-intro dt-container-wrapper"
      aria-label={sectionLandmark}
      data-col={introProducts.products?.length}
    >
      <div className="dt-intro__holder">
        <div className="dt-intro__img-holder">
          <UmbracoImage className="dt-intro__img" image={image} alt={imageAlt} />
        </div>

        <Container fluid className="dt-intro__content">
          <HTag size={1} regularText={regularTitle} boldText={boldTitle} />
          <p className="dt-intro__text">{descriptionText}</p>
          <Button href={extractUrlFromMultiPicker(buttonURL)} size="lg">
            {buttonText}
          </Button>
        </Container>
      </div>
      {introProducts?.products ? (
        <Container className="dt-intro__products" fluid>
            <IntroProducts products={introProducts.products} />
        </Container>
      ) : null}
    </section>
  );
};

export default Intro;
