import React, { useEffect, FC } from 'react';
import cn from 'classnames';
import UmbracoImage from 'gatsby-theme-dettol/src/components/common/Image/UmbracoImage';
import extractUrlFromMultiPicker from 'gatsby-theme-dettol/src/utils/urlMultiPicker';
import { isBrowser } from 'gatsby-theme-dettol/src/utils/browser';
import ProductCarousel from 'gatsby-theme-dettol/src/components/common/ProductCarousel';

import { CarouselMasks } from 'gatsby-theme-dettol/src/components/common/ProductCarousel/models';

import './IntroProducts.scss';

type TIntroProductsComponentProps = {
  products: HomepageTypes.TProductProps[];
};

type TIntroProductComponentProps = {
  product: HomepageTypes.TProductProps;
  isCarousel?: boolean;
};

const prefix = 'dt-intro';
const holderClass = `${prefix}__products-holder`;
const carouselHolderClass = `${prefix}__products-carousel-holder`;
const baseClass = `${prefix}__products-box`;
const carouselMaxWidth = 576; // sm screen size

const IntroProduct: FC<TIntroProductComponentProps> = ({
  product: { imageAlt, title, subTitle, link, image },
  isCarousel,
}) => {
  const containerClass = cn(baseClass, {
    [`${baseClass}--carousel`]: isCarousel,
  });

  return (
    <a href={extractUrlFromMultiPicker(link)} className={containerClass} key={title + subTitle}>
      <div className={`${baseClass}-holder`}>
        <span className={`${baseClass}-label`}>{subTitle}</span>
        <strong className={`${baseClass}-title`}>{title}</strong>
      </div>
      <UmbracoImage className={`${baseClass}-img`} image={image} alt={imageAlt || ''} />
    </a>
  );
};

const IntroProducts: FC<TIntroProductsComponentProps> = ({ products }) => {
  const [showCarousel, setShowCarousel] = React.useState(true);

  useEffect(() => {
    if (isBrowser()) {
      const handleResize = () => {
        setShowCarousel(window.innerWidth < carouselMaxWidth);
      };

      handleResize();
      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  if (showCarousel)
    return (
      <div className={carouselHolderClass}>
        <ProductCarousel
          isBtnGroup
          limitWidth
          visibleElements
          infinite={false}
          type={CarouselMasks.noMasks}
        >
          {products.map((p) => (
            <IntroProduct key={p.title + p.subTitle} product={p} isCarousel />
          ))}
        </ProductCarousel>
      </div>
    );

  return (
    <div className={holderClass}>
      {products.map((p) => (
        <IntroProduct key={p.title + p.subTitle} product={p} />
      ))}
    </div>
  );
};

export default IntroProducts;
